import createReducer from 'granite-admin/utils/ducks/create-reducer';

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const UPDATE_ROLLOVER_YEAR = 'rollover/UPDATE_ROLLOVER_YEAR';

export function updateRolloverYear(rollOverYear) {
  return {
    type: UPDATE_ROLLOVER_YEAR,
    data: rollOverYear,
  };
}

const defaultState = {
  year: '',
};

export default createReducer(defaultState, {
  [UPDATE_ROLLOVER_YEAR]: (state, action) => ({ ...state, year: action.data }),
});
