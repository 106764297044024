import React, { useEffect, useMemo, useState, useContext } from 'react';

import { Box } from "grommet";


const HealthPage = () => {
    return (
        <Box>
            <h1>This is a health Check</h1>
        </Box>
    );
}

export default HealthPage;